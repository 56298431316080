import React, { Component } from 'react'
import Main from './Main';

class Selection extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedTopic:null,
            formattedTasks:[],
            title: 'Please Select the Quiz',
            buttonText: 'Submit',
        }
        this.selectTopicHandler = this.selectTopicHandler.bind(this);
    }

    componentWillMount(){
        // let tasksArray = [];
        // let tasks = Object.values(this.props.values);
        // tasks.forEach(k=>
        //     Object.entries(k).forEach(([key, value])=>
        //     tasksArray.push({name:key, value:value})));
        // this.setState({formattedTasks:tasksArray})
    }    

    selectTopicHandler = (event) => {
        console.log(event)
        //selectedTopic = event.target.value
    }

    render(){
        const loadOptions = this.state.formattedTasks.map((value, i) => {
            return <option key={i} value={value.name}>{value.name}</option>
        })

        let { title, text, buttonText } = this.state;

        let { style, selectQuiz } = this.props;

        return(
            // <div>
            //     {/* {this.state.values} */}
            //         <select onChange={this.selectTopicHandler}>
            //             {loadOptions}                 
            //         </select>
            //         {this.state.selectedTopic != null ? <Main topic={this.state.selectedTopic}/> : null}                
            // </div>    
            
            <div className="popup-container" style={style}>
                <div className="container">
                    <div className="col-md-6 col-md-offset-3">
                        <div className="popup">
                            <h1>{title}</h1>
                            {/* <p dangerouslySetInnerHTML={this.createMarkup(text)} /> */}
                            {/* <div class="row">
                                <div class="col-6 col-md-4">
                                                                        
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-lg-offset-4">
                                    <div class="input-group">
                                        <select class="form-control">
                                            <option>Mustard</option>
                                            <option>Ketchup</option>
                                            <option>Relish</option>
                                        </select> 
                                        <span class="input-group-btn">
                                            <button class="btn btn-default" type="button">Go!</button>
                                        </span>
                                    </div>
                                </div>
                            </div> */}

                            <select className="form-control selectQuiz">
                                <option>Mustard</option>
                                <option>Ketchup</option>
                                <option>Relish</option>
                            </select>

                            <button className="fancy-btn" selected={''} onClick={selectQuiz}>{buttonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}

export default Selection