import React from 'react';
import logo from './logo.svg';
// import './App.css';
import './styles/app.css'
import Main from './components/Main'
import Selection from './components/Selection'
import Data from './data/sampleData'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Main />
        {/* <Selection values={Data}/>         */}
      </header>      
    </div>
  );
}

export default App;
