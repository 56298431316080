import React from 'react';
import data from '../data/data';
import Answers from './Answers';
import Popup from './Popup';
import Footer from './Footer';
import Selection from './Selection';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nr: 0,
            total: data.length,
            showButton: false,
            questionAnswered: false,
            score: 0,
            displayPopup: 'flex',
            displaySelectPopup: 'none',
            selectedQuiz: '',
            classNames: ['', '', '', '']
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleIncreaseScore = this.handleIncreaseScore.bind(this);
        this.handleSelectQuiz = this.handleSelectQuiz.bind(this);
    }

    pushData(nr) {
        this.setState({
            question: data[nr].question,
            answers: [data[nr].answers[0], data[nr].answers[1], data[nr].answers[2], data[nr].answers[3] ],
            correct: data[nr].correct,
            nr: this.state.nr + 1
        });
    }

    componentWillMount() {
        //let { nr } = this.state;
        // this.pushData(nr);
    }

    nextQuestion() {
        let { nr, total, score } = this.state;
        
        if(nr === total){
            this.setState({
                displayPopup: 'flex'
            });
        } else {
            this.pushData(nr);
            this.setState({
                showButton: false,
                questionAnswered: false,
                classNames:['', '', '', '']
            });
        }

    }

    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: true
        })
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            displaySelectPopup: 'flex'
            //nr: 1
        });
    }

    handleSelectQuiz(event){
        this.setState({
            //selectedQuiz:
            displaySelectPopup: 'none',
            nr: 1
        });
        let { nr } = this.state;
        this.pushData(nr);
        console.log(event);
    }

    handleIncreaseScore() {
        this.setState({
            score: this.state.score + 1
        });
    }

    render() {
        let { nr, total, question, answers, correct, showButton, questionAnswered, displayPopup, score, classNames, selectedQuiz, displaySelectPopup} = this.state;

        return (
            <div className="container">

                <Popup style={{display: displayPopup}} score={score} total={total} startQuiz={this.handleStartQuiz}/>
                <Selection style={{display: displaySelectPopup}} selected={selectedQuiz} selectQuiz={this.handleSelectQuiz}/>
                {nr!=0?
                    <div className="row">
                        <div className="col-lg-10 col-lg-offset-1">
                            <div id="question">
                                <h4>Question {nr}/{total}</h4>
                                <p>{question}</p>
                            </div>
                            <Answers answers={answers} correct={correct} classNames={classNames} showButton={this.handleShowButton} isAnswered={questionAnswered} increaseScore={this.handleIncreaseScore}/>
                            <div id="submit">
                                {showButton ? <button className="fancy-btn" onClick={this.nextQuestion} >{nr===total ? 'Finish quiz' : 'Next question'}</button> : null}
                            </div>
                        </div>
                    </div>
                : null}
                {/* <Footer /> */}
            </div>
        );
    }
};

export default Main
